import React from 'react'
import {IFuncItem, parseTagComponent} from '@component/Text/parseTagComponent'

interface IProps {
    children?: any
    style?: React.CSSProperties
    className?: string
    funcList?: IFuncItem[]
    onClick?: () => void
    isHoverStyle?: boolean
}

const TagSpan: React.FC<IProps> = ({style, className, children, funcList, onClick, isHoverStyle}) => {
    return (
        <span
            style={style}
            className={`font-sans antialiased ${className}`}
            suppressHydrationWarning={true}
            onClick={onClick}>
            {parseTagComponent(children, funcList, isHoverStyle)}
        </span>
    )
}

export default TagSpan
