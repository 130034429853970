import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface IDailyReport {
    balance?: number
    created_at: number
    deposits?: number
    earning?: number
    emoji?: string
    message?: string
    score?: number
    user_id?: string
    withdraws?: number
}

export const apiGetDailyReport = async (id?: string): Promise<ApiRes<IDailyReport>> => {
    return await apiGet(`report/portfolio?id=${id}`)
}
