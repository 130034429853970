import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {IConfirmResponse} from '@api/auths/register/Register'

export const apiEditPassword = async (oldPassword: string, newPassword: string): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost('editpass', {
        old: oldPassword,
        new: newPassword,
    })
}
