import React from 'react'
import useText from '@hook/useText'

interface IProps extends React.HTMLAttributes<HTMLParagraphElement> {
    children?: any
    className?: string
    enableHyperlink?: boolean
    enablePreWhiteSpace?: boolean
    inlineStyle?: any
}

const Text: React.FC<IProps> = ({
    className,
    enableHyperlink = false,
    enablePreWhiteSpace = true,
    children,
    inlineStyle,
    ...props
}) => {
    const {renderHyperlink} = useText()

    return (
        <p
            className={`antialiased font-sans ${className} ${
                enablePreWhiteSpace === true ? 'whitespace-pre-wrap' : ''
            }`}
            style={{...inlineStyle}}
            suppressHydrationWarning={true}
            {...props}>
            {enableHyperlink ? renderHyperlink(children as string) : (children as string)}
        </p>
    )
}

export default Text
