import React, {useEffect} from 'react'
import {useLoginStore} from '@store/LoginStore'
import usePreserveScroll from '@hook/usePreserveScroll'

import * as amplitude from '@amplitude/analytics-browser'
import {amplitudeConfig} from '@constant/config'

const LoginExtender = () => {
    const refreshLoginCookieExpireTime = useLoginStore(state => state.refreshLoginCookieExpireTime)
    const userId = useLoginStore(state => state.id)
    usePreserveScroll()

    useEffect(() => {
        refreshLoginCookieExpireTime()
        amplitude.init(amplitudeConfig.amplitudeKey, userId, {defaultTracking: false})
    }, [userId, refreshLoginCookieExpireTime])

    return <></>
}

export default LoginExtender
