import {IMe, IProfile} from '@type/profile'
import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {IApiBaseProps} from '@api/user/sidebar/getSidebar'

export interface IApiGetMeProps extends IApiBaseProps {}

export const apiFetchMe = async (props?: IApiGetMeProps): Promise<ApiRes<IMe>> => {
    return await apiGet<IMe>(`me`, {cookies: props?.cookie})
}

export const fetchProfile = async (id: string): Promise<ApiRes<IProfile>> => {
    return await apiGet(`user/profile/${id}`)
}
