import React, {useState} from 'react'
import Image from 'next/image'
import {addImageWebpQuery} from '@util/images'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    src: any
    containerClassName?: string
    imageClassName?: string
    width?: number
    height?: number
    placeHolder?: any
    alt?: string
    unoptimized?: boolean
    isResize?: boolean
    isFetchPriority?: boolean
    loadingType?: 'lazy' | 'eager'
}

const WizImage: React.FC<IProps> = ({
    src,
    containerClassName,
    imageClassName,
    width,
    height,
    placeHolder,
    alt = '',
    unoptimized,
    isResize,
    loadingType,
    isFetchPriority,
}) => {
    const [image, setImage] = useState(initImageSource(src) ?? placeHolder)

    function initImageSource(src: any) {
        if (!src) return undefined
        else if (typeof src == 'string') {
            if (src?.length < 10) {
                return undefined
            } else {
                return addImageWebpQuery(src, width, height, isResize)
            }
        } else return src
    }

    useIsomorphicLayoutEffect(() => {
        setImage(initImageSource(src) ?? placeHolder)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, placeHolder])

    if (!image) return null
    return (
        <div className={`${containerClassName}`}>
            <Image
                suppressHydrationWarning={true}
                style={{width: width, height: height}}
                className={`${imageClassName} object-cover`}
                src={image}
                loading={loadingType ? loadingType : typeof src === 'string' ? 'lazy' : 'eager'}
                priority={typeof src === 'string' ? undefined : true}
                alt={alt}
                quality={100}
                width={width}
                height={height}
                fetchPriority={isFetchPriority ? 'high' : 'auto'}
                unoptimized={unoptimized}
                onError={() => setImage(placeHolder)}
            />
        </div>
    )
}

export default React.memo(WizImage)
