import React from 'react'
import {IFuncItem, parseTagComponent} from '@component/Text/parseTagComponent'

interface IProps {
    children?: any
    className?: string
    funcList?: IFuncItem[]
    isHoverStyle?: boolean
}

const TagText: React.FC<IProps> = ({className, children, funcList, isHoverStyle}) => {
    return (
        <p className={`antialiased font-sans ${className}`} suppressHydrationWarning={true}>
            {parseTagComponent(children, funcList, isHoverStyle)}
        </p>
    )
}

export default TagText
