import {useIsomorphicLayoutEffect} from 'usehooks-ts'
import {isMobile} from 'react-device-detect'

const WindowStateInitializer = () => {
    useIsomorphicLayoutEffect(() => {
        const isAndroid = /Android|BlackBerry|Nokia/i.test(navigator.userAgent)
        const isWindow = navigator.userAgent?.includes('Win')

        if (isMobile || isAndroid) {
            document?.documentElement?.classList?.add('mobile')
        } else if (isWindow) {
            document?.documentElement?.classList?.add('window')
        } else {
            document?.documentElement?.classList?.add('mac')
        }
    }, [])

    return null
}

export default WindowStateInitializer
