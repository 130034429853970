import {useMemo, useState} from 'react'
import {ITipId} from '@component/tooltip/TipContext'
import {equalString} from '@util/strings'

const usePageTipHandler = () => {
    const [tipList, setTipList] = useState<ITipId[]>([])
    const nextOpenTip = useMemo(() => {
        return tipList?.at(0)
    }, [tipList])

    const registerTip = (type?: string, id?: string) => {
        if (!type || !id) return
        if (localStorage.getItem(getTipStorageId(type)) === 'true') return
        const sameTypeTip = tipList?.find(item => equalString(item?.type, type))
        if (sameTypeTip) return

        tipList.push({type: type, id: id})
        setTipList([...tipList])
    }

    const popTip = (type?: string) => {
        if (!type) return
        const tipType = tipList?.find(item => equalString(item?.type, type))?.type
        setTipList([...tipList?.slice(1)])
        readTip(tipType)
    }

    const getTipStorageId = (type?: string) => {
        return `tip.${type}.isRead`
    }

    const readTip = (type?: string) => {
        return setTip(type, 'true')
    }

    const getTip = (type?: string) => {
        return localStorage.getItem(getTipStorageId(type))
    }

    const setTip = (type?: string, status?: string) => {
        return localStorage.setItem(getTipStorageId(type), status)
    }

    return {registerTip, tipList, nextOpenTip, popTip, getTip, setTip}
}

export default usePageTipHandler
