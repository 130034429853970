import React from 'react'

interface IProps {
    children?: any
    id?: string
    className?: string
    onClick?: React.MouseEventHandler<HTMLParagraphElement>
}

const Span: React.FC<IProps> = ({id, className, onClick, children}) => {
    return (
        <span
            id={id}
            className={`font-sans ${className} antialiased whitespace-pre-wrap`}
            onClick={onClick}
            suppressHydrationWarning={true}>
            {children as string}
        </span>
    )
}

export default Span
