import React from 'react'

type TypeBackgroundColor = 'default' | 'gray' | 'skyblue'

interface IProps {
    className?: string
    children?: any
    backgroundColorClassName?: string
    backgroundColorType?: TypeBackgroundColor
    onClick?: (e?: any) => void
}

const WizIcon: React.FC<IProps> = ({className, children, backgroundColorClassName, backgroundColorType, onClick}) => {
    const getIconBackgroundTypeColor = () => {
        switch (backgroundColorType) {
            case 'gray':
                return 'bg-gray09 dark:bg-dark_gray09'
            case 'default':
                return 'bg-white dark:bg-bg_dark_white02'
            case 'skyblue':
                return 'bg-blue_tint02 dark:bg-dark_blue_tint02'
        }
    }

    return (
        <div
            onClick={onClick && onClick}
            className={`rounded-full ${className} ${backgroundColorClassName} ${getIconBackgroundTypeColor()}`}>
            {children}
        </div>
    )
}

export default WizIcon
