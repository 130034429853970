import React from 'react'

interface IProps {
    className?: string
    onClick?: (e?: any) => void
    showHover?: boolean
    children?: any
    dataCy?: string
}

const WizIconButton: React.FC<IProps> = ({className, onClick, children, showHover = true, dataCy}) => {
    return (
        <div
            data-cy={dataCy}
            onClick={e => {
                onClick && onClick(e)
                onClick && e?.stopPropagation()
            }}
            className={`rounded-full fill-gray02 dark:fill-dark_gray02 cursor-pointer hover:fill-gray01 dark:hover:fill-dark_gray01 hover:stroke:fill-gray01 dark:fill-dark_gray01 flex-none ${
                showHover ? 'hover:bg-transparent01 dark:hover:bg-dark_transparent01' : ''
            } ${className}`}>
            {children}
        </div>
    )
}

export default WizIconButton
