import React, {useMemo} from 'react'
import Text from '@component/Text/Text'
import Span from '@component/Text/Span'

interface IProps {
    className?: string
    minLength?: number
    maxCount: number
    text?: string
    enabled?: boolean
    type?: 'input' | 'textarea'
}

const InputTextCount: React.FC<IProps> = ({className, minLength = 0, maxCount, text, enabled, type = 'input'}) => {
    const isCounterRed = useMemo(() => text?.length > maxCount || text?.length < minLength, [minLength, maxCount, text])

    if (type === 'textarea') {
        return (
            <div className={className}>
                {
                    <Text className={`text-body2 text-gray01 dark:text-dark_gray01`}>
                        <Span className={`text-ti2 ${isCounterRed && 'text-red_shade dark:text-dark_red_shade'}`}>
                            {text?.length ?? 0}
                        </Span>
                        /{maxCount}
                    </Text>
                }
            </div>
        )
    }
    return (
        <div className={className}>
            {
                <Text className={`text-body2 text-gray02 dark:text-dark_gray02`}>
                    <Span
                        className={`font-bold ${
                            isCounterRed
                                ? 'text-red_shade dark:text-dark_red_shade'
                                : 'text-gray01 dark:text-dark_gray01'
                        }`}>
                        {text?.length ?? 0}
                    </Span>
                    /{maxCount}
                </Text>
            }
        </div>
    )
}

export default InputTextCount
