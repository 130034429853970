import React, {useEffect, useMemo, useRef, useState} from 'react'
import IconCheck from '@svg/common/ic_check.svg'
import IconDel from '@svg/common/ic_del.svg'
import Text from '@component/Text/Text'
import IconHidePassword from '@svg/common/ic_pw_off.svg'
import IconShowPassword from '@svg/common/ic_pw_on.svg'
import WizIconButton from '@component/buttons/WizIconButton'
import useDidMountEffect from '@hook/UseDidMountEffect'

export enum InputState {
    DEFAULT = 'DEFAULT',
    CONFIRM = 'CONFIRM',
    ERROR = 'ERROR',
}

type inputType = 'text' | 'password' | 'number'

interface IProps {
    text?: string
    className?: string
    containerClassName?: string
    placeholder?: string
    disabled?: boolean
    defaultDescription?: string
    errorDescription?: string
    state?: InputState
    type?: inputType
    autoComplete?: 'username' | 'current_password' | 'one-time-code' | 'new-password' | 'off' | undefined
    pattern?: RegExp
    autoFocus?: boolean
    textType?: 'uppercase' | 'lowercase' | undefined
    dataCy?: string
    renderLeftArea?: JSX.Element
    renderRightArea?: JSX.Element
    renderInputRightArea?: JSX.Element
    renderInputCount?: JSX.Element
    isUseClear?: boolean

    onChanged?(text: string): void

    onPressEnter?(): void
}

const inputClassName =
    'box-border text-body1 text-gray01 dark:text-dark_gray01 disabled:text-gray04 dark:disabled:text-dark_gray04 dark:disabled:bg-dark_gray07 w-full'

const WizInputText: React.FC<IProps> = ({
    text,
    containerClassName,
    className,
    onChanged,
    placeholder,
    disabled = false,
    state = InputState.DEFAULT,
    errorDescription,
    defaultDescription,
    type = 'text',
    onPressEnter,
    autoComplete,
    pattern,
    renderLeftArea,
    renderRightArea,
    autoFocus,
    renderInputRightArea,
    renderInputCount,
    textType = undefined,
    dataCy,
    isUseClear = true,
}) => {
    const [inputText, setInputText] = useState(text)
    const [isHidePassword, setIsHidePassword] = useState(true)

    const renderContainerClassName = useMemo(() => {
        let result = `border rounded-[5px] ${
            !disabled && 'bg-white dark:bg-bg_dark_white01'
        } pl-[15px] pr-[10px] py-[9.5px]`

        if (state == InputState.ERROR) {
            result += ' border-red dark:border-dark_red'
        } else {
            result +=
                ' border-gray06 dark:border-dark_gray06 focus-within:border-gray01 dark:focus-within:border-dark_gray01'
        }

        if (disabled) result += ' bg-gray07 dark:bg-dark_gray07'
        if (containerClassName) result += ` ${containerClassName}`

        return result
    }, [containerClassName, disabled, state])

    const renderIcon = (inputState: InputState) => {
        switch (inputState) {
            case InputState.DEFAULT:
                return null
            case InputState.CONFIRM:
                return <IconCheck className={'w-[16px] flex-none fill-primary dark:fill-dark_primary'} />
            case InputState.ERROR:
                return null
        }
    }

    const renderPasswordToggleButton = () => {
        switch (isHidePassword) {
            case true:
                return (
                    <WizIconButton onClick={() => setIsHidePassword(false)}>
                        <IconHidePassword
                            onClick={() => setIsHidePassword(false)}
                            className={'w-[24px] fill-gray02 dark:fill-dark_gray02'}
                        />
                    </WizIconButton>
                )
            case false:
                return (
                    <WizIconButton onClick={() => setIsHidePassword(true)}>
                        <IconShowPassword className={'w-[24px] fill-gray02 dark:fill-dark_gray02'} />
                    </WizIconButton>
                )
        }
    }

    const renderDescription = (inputState: InputState) => {
        if (inputState === InputState.ERROR && errorDescription) {
            return (
                <Text className="text-[12px] mt-[5px] text-red_shade dark:text-dark_red_shade">{errorDescription}</Text>
            )
        } else if (defaultDescription) {
            return <Text className="text-[12px] mt-[5px] text-gray03 dark:text-dark_gray03">{defaultDescription}</Text>
        } else {
            return null
        }
    }

    const inputRef = useRef(null)
    const onClickContainer = () => {
        inputRef.current && inputRef.current.focus()
    }

    const onClickClear = () => {
        setInputText('')
    }

    const onKeyDownEnter = e => {
        if (e.key === 'Enter') {
            onPressEnter && onPressEnter()
        }
    }

    useDidMountEffect(() => {
        onChanged(inputText)
    }, [inputText])

    useEffect(() => {
        if (pattern) setInputText(text.replace(pattern, ''))
        else if (inputText !== text) {
            setInputText(text)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    const getInputType = () => {
        switch (type) {
            case 'password':
                if (isHidePassword) return 'password'
                else return 'text'
            default:
                return type
        }
    }

    return (
        <div className={`${className}`} data-cy={dataCy ?? ''}>
            <div className={'flex space-x-1'}>
                {renderLeftArea}
                <div className={`${renderContainerClassName} relative flex-1 group`} onClick={onClickContainer}>
                    <div className={`flex items-center space-x-2`}>
                        <input
                            ref={inputRef}
                            className={`${inputClassName} ${
                                !isUseClear && 'text-center'
                            } font-sans peer dark:bg-transparent placeholder:text-gray03 disabled:placeholder:text-gray04 dark:placeholder:text-dark_gray03 dark:disabled:placeholder:text-dark_gray04`}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            autoFocus={autoFocus}
                            onChange={e => {
                                let text = e.target.value
                                switch (textType) {
                                    case 'uppercase':
                                        text = text?.toUpperCase()
                                        break
                                    case 'lowercase':
                                        text = text?.toLowerCase()
                                        break
                                }

                                if (pattern) setInputText(text.replace(pattern, ''))
                                else setInputText(text)
                            }}
                            value={inputText ?? ''}
                            disabled={disabled}
                            type={getInputType()}
                            onKeyDown={onKeyDownEnter}
                        />

                        {renderInputRightArea}

                        {inputText && isUseClear && (
                            <div onClick={onClickClear} className={'hidden sm:block cursor-pointer'}>
                                <IconDel className={'w-[16px] fill-gray02 dark:fill-dark_gray02'} />
                            </div>
                        )}
                        {type == 'password' && renderPasswordToggleButton()}
                        {renderIcon(state)}
                    </div>
                </div>
                {renderRightArea}
            </div>

            <div className={'flex items-center justify-between'}>
                {renderDescription(state)}
                {renderInputCount}
            </div>
        </div>
    )
}

export default WizInputText
