import React from 'react'
import Link from 'next/link'
import Text from '@component/Text/Text'
import {createLog} from '@util/logs'

type Props = {
    text: string
    href: string
    textColorClassName?: string
    target?: string
}

const FooterButton: React.FC<Props> = ({text, href, textColorClassName, target = '_self'}) => {
    return (
        <Link href={href} onClick={() => createLog('event', 'footer_click_item', {path: href})} target={target}>
            <Text className={`cursor-pointer text-ti3 ${textColorClassName}`}>{text}</Text>
        </Link>
    )
}

export default FooterButton
