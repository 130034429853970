import {useEffect} from 'react'
import {initializeDatadog} from '@lib/datadog'

const DatadogInitializer = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const loadDatadog = async () => {
                await initializeDatadog()
            }
            loadDatadog()
        }
    }, [])

    return null
}

export default DatadogInitializer
