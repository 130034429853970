import React, {useEffect} from 'react'
import {useCurrencyStore} from '@store/CurrencyStore'
import useQueryGetPoint from '@hook/query/account/point/useQueryGetPoint'

const PresetLoader = () => {
    const fetchCurrencies = useCurrencyStore(state => state.fetchCurrencies)

    useEffect(() => {
        fetchCurrencies()
    }, [fetchCurrencies])

    return <></>
}

export default PresetLoader
